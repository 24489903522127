import React from "react";
import "./App.css";
import Axios from "axios"
function App() {
  const [count, setCount] = React.useState(0);

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    setCount(count - 1);
  };

  const login=()=>{
    Axios.post("http://localhost:3000/test",{
      username: 'test'
    }).then((response)=>(alert(response)))
  }
  return (
    <>
      <p>{count}</p>
      <button onClick={login}>Increment</button>
      <button onClick={decrement}>Decrement</button>
    </>
  );
}

export default App;